@if (showNavigation$ | async) {
  @if (placement$ | async; as placement) {
    <ed-default-header
      [language]="language$ | async"
      [isLoggedIn]="isLoggedIn$ | async"
      [currentNavItems]="mainNavigationItems$ | async"
      [originalNavItems]="mainNavigationItems$ | async"
      [isServiceNavigationAvailable]="serviceNavigationActive$ | async"
      [serviceNavigationLink]="serviceNavigationLink$ | async"
      [serviceNavigationTitle]="serviceNavigationTitle$ | async"
      [brandLogoLinkUrl]="brandLogoLinkUrl$ | async"
      [loginUrlLabel]="loginUrlLabel$ | async"
      [loginUrl]="loginUrl"
      [isNavigationAvailable]="isMainNavigationAvailable$ | async"
      wlTrackImpression="navigation-impression"
      trackImpressionMode="any"
      [trackImpressionInfo]="{
        name: 'headernavigation',
        placement: placement,
      }"
    >
    </ed-default-header>
  }
}
