@if (placement$ | async; as placement) {
  <ed-minimal-header
    [showHelp]="showHelp$ | async"
    [brandLogoLink]="brandLogoLink$ | async"
    [ecmpHeader]="ecmpHeader$ | async"
    (helpRequested)="dispatchHelpAction()"
    (logoClicked)="onLogoClicked()"
    wlTrackImpression="navigation-impression"
    trackImpressionMode="any"
    [trackImpressionInfo]="{
      name: 'headernavigation',
      placement: placement,
    }"
  >
  </ed-minimal-header>
}
