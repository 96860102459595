<!-- Desktop view -->
<header class="header">
  <ng-container *ngTemplateOutlet="desktopNavigation"></ng-container>

  <div class="fixed-header">
    <ng-container *ngTemplateOutlet="mobileNavigation"></ng-container>
  </div>
</header>

<ng-template #desktopNavigation>
  <nav
    class="navbar navbar-expand-md navbar-light bg-white d-none d-lg-flex"
    aria-label="Menu"
  >
    <div class="container">
      <a
        class="navbar-brand navbar__logo"
        *wlGenericLink="
          { value: { href: brandLogoLinkUrl || '/' } };
          extras: { queryParamsHandling: 'preserve' }
        "
      >
        <img
          [src]="'assets/logo.svg?v=2' | deployUrl"
          alt="Logo energiedirect"
        />
      </a>

      @if (isNavigationAvailable) {
        <div class="d-flex align-items-center">
          @for (menuItem of originalNavItems; track menuItem) {
            @if (menuItem.children && menuItem.children.length > 0) {
              <wl-navbar-dropdown [item]="menuItem"></wl-navbar-dropdown>
            }

            @if (menuItem.children && menuItem.children.length === 0) {
              <wl-navbar-item
                [navItem]="menuItem"
                [language]="language"
                (clicked)="isNavigationOpen = false"
              >
              </wl-navbar-item>
            }
          }
        </div>
      }

      @if (isServiceNavigationAvailable) {
        <div ngbDropdown placement="bottom-right" class="header__tools">
          <button
            class="btn ed-header-button"
            [title]="serviceNavigationTitle"
            wlAutomationId="serviceNavigation"
            id="serviceNavigation"
            ngbDropdownToggle
          >
            <fa-icon icon="user"></fa-icon>
            <span>{{ serviceNavigationTitle }}</span>
          </button>
          <div
            ngbDropdownMenu
            placement="bottom-end"
            aria-labelledby="serviceNavigation"
            class="header__tools-menu"
          >
            <wl-header-tools-container
              [loginUrl]="loginUrl"
              [loginUrlLabel]="loginUrlLabel"
            ></wl-header-tools-container>
          </div>
        </div>
      } @else {
        <div class="header__tools">
          <a
            role="button"
            wlAutomationId="serviceLink"
            [title]="serviceNavigationLink?.label"
            class="btn ed-header-button service-nav-button"
            *wlGenericLink="serviceNavigationLink"
          >
            <fa-icon icon="user" class="service-nav-icon"></fa-icon>
            <span>{{ serviceNavigationLink?.label }}</span>
          </a>
        </div>
      }
    </div>
  </nav>
</ng-template>

<ng-template #mobileNavigation>
  <div class="navbar bg-white d-flex d-lg-none">
    <div class="nav-item">
      <wl-off-canvas
        direction="left-to-right"
        [openButtonTitle]="'menu' | translate"
        [title]="history.length > 0 ? selectedItem?.name : ('menu' | translate)"
        icon="bars"
        [showTitleCollapsed]="true"
        [(open)]="isNavigationOpen"
        (openChange)="onOpenChange($event)"
        (back)="previousNavItem()"
        [showBackButton]="history.length > 0"
      >
        <nav class="flex-column" aria-label="Menu" wlCSCapture>
          <ul class="reset-list-styles">
            @for (menuItem of currentNavItems; track menuItem) {
              <li class="navbar-list-item">
                @if (menuItem.children && menuItem.children.length > 0) {
                  <button
                    (click)="nextNavItem(menuItem)"
                    class="d-block navbar-list-btn"
                  >
                    <wl-partial-list-item
                      [label]="menuItem.name"
                      [iconTrailing]="'chevron-right'"
                    >
                    </wl-partial-list-item>
                  </button>
                }
                @if (menuItem.children && menuItem.children.length === 0) {
                  <a
                    class="d-block navbar-list-link"
                    (click)="collapseSideNavigation()"
                    *wlGenericLink="{ value: { href: menuItem.path } }"
                  >
                    <wl-partial-list-item
                      [label]="menuItem.name"
                      [iconTrailing]="'chevron-right'"
                    >
                    </wl-partial-list-item>
                  </a>
                }
              </li>
            }
          </ul>
        </nav>
      </wl-off-canvas>
    </div>

    <div class="nav-item">
      <ng-container *ngTemplateOutlet="logo"></ng-container>
    </div>

    <div class="nav-item">
      @if (isServiceNavigationAvailable) {
        <wl-off-canvas
          direction="right-to-left"
          [wlAutomationId]="'serviceNavigation'"
          [openButtonTitle]="serviceNavigationTitle"
          [title]="serviceNavigationTitle"
          [subtitle]=""
          icon="user"
          [showTitleCollapsed]="true"
          [(open)]="isServiceNavigationOpen"
        >
          <div class="d-block">
            <wl-header-tools-container
              (navItemClick)="isServiceNavigationOpen = false"
              [loginUrl]="loginUrl"
              [loginUrlLabel]="loginUrlLabel"
            >
            </wl-header-tools-container>
          </div>
        </wl-off-canvas>
      } @else {
        <a
          wlAutomationId="serviceLink"
          class="btn btn-icon header__tools-link ed-header-button service-nav-button"
          aria-label="Naar Mijn energiedirect"
          *wlGenericLink="serviceNavigationLink"
        >
          <fa-icon icon="user" [fixedWidth]="true"></fa-icon>
        </a>
      }

      <ng-template #serviceLink>
        <a
          wlAutomationId="serviceLink"
          class="btn btn-icon header__tools-link ed-header-button service-nav-button"
          aria-label="Naar Mijn energiedirect"
          *wlGenericLink="serviceNavigationLink"
        >
          <fa-icon icon="user" [fixedWidth]="true"></fa-icon>
        </a>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #logo>
  <a
    class="navbar-brand navbar__logo"
    *wlGenericLink="{ value: { href: brandLogoLinkUrl || '/' } }"
  >
    <img [src]="'assets/logo.svg?v=2' | deployUrl" alt="Logo energiedirect" />
  </a>
</ng-template>
