import { Component, Inject } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@core/config-angular';
import { EnvironmentConfig } from '@core/config-models';
import { getSiteContext } from '@core/analytics';
import { Store } from '@ngrx/store';

import { MinimalHeaderContainerComponent } from '../../../shared/minimal-header';

@Component({
  selector: 'ed-minimal-header-container',
  templateUrl: './ed-minimal-header-container.component.html',
})
export class EDMinimalHeaderContainerComponent extends MinimalHeaderContainerComponent {
  public placement$ = getSiteContext(this.store$, this.config);

  constructor(
    override readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {
    super(store$);
  }
}
